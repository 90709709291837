















import Vue from 'vue';
import AddNewRequestPage from './AddNewRequestPage.vue';
import ChangePricingV2 from './ChangePricingV2.vue';

export default Vue.extend({
  components: {
    ChangePricingV2,
    AddNewRequestPage,
  },
  data() {
    return {
      v: '2021-02',
    };
  },
  computed: {
    d(): string {
      return this.$d(new Date(this.v), 'MONTH_YEAR');
    },
  },
});
