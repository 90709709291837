



















import Vue from 'vue';
import Change from '@/components/changelog/Change.vue';

export default Vue.extend({
  components: {
    Change,
  },
});
